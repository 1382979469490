@font-face {
  font-family: 'InriaSans';
  src: url('./fonts/InriaSans-Regular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'InriaSans';
  src: url('./fonts/InriaSans-Bold.ttf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'InriaSans';
  src: url('./fonts/InriaSans-BoldItalic.ttf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'InriaSans';
  src: url('./fonts/InriaSans-Italic.ttf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'InriaSans';
  src: url('./fonts/InriaSans-Light.ttf') format('opentype');
  font-weight: 300; /* Light font weight */
  font-style: normal;
}

@font-face {
  font-family: 'InriaSans';
  src: url('./fonts/InriaSans-LightItalic.ttf') format('opentype');
  font-weight: 300; /* Light font weight */
  font-style: italic;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/RoobertRegular.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/RoobertRegularItalic.ttf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/RoobertMedium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/RoobertMediumItalic.ttf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/RoobertHeavy.ttf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/RoobertHeavyItalic.ttf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/RoobertSemiBold.ttf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/RoobertSemiBoldItalic.ttf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/RoobertBold.ttf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/RoobertBoldItalic.ttf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/RoobertLight.ttf') format('opentype');
  font-weight: 300; /* Light font weight */
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('./fonts/RoobertLightItalic.ttf') format('opentype');
  font-weight: 300; /* Light font weight */
  font-style: italic;
}


@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-VariableFont_wght.ttf') format('opentype');
}

@font-face {
  font-family: 'Simula-Book';
  src: url('./fonts/Simula-Book.ttf') format('opentype');
}

:root {
  --main-color: #242525;
  --background-color: #FFFAF6;
}

html, body, div#root {
  height: 100%;
}

* { 
  box-sizing: border-box;
  font-family: 'Manrope';
  margin: 0px;
}

ul {
  padding-left: 20px;
}
